<template>
  <el-dialog
    title=""
    :visible.sync="numberofdrawsShow"
    width="40%"
    :show-close="false"
  >
    <div class="numberofdraws">
      <div class="numberofdraws-title">
        <img
          src="http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/drawalotteryorraffle/X.png"
          alt=""
          class="numberofdraws-x"
          @click="numberofdrawsShow = false"
        />获取抽奖次数
      </div>
      <div class="numberofdraws-text">
        <div class="popupClass-content-numberofdraws-title">
          如何获取抽奖次数
        </div>
        <div class="popupClass-content-numberofdraws">
          <div
            class="popupClass-content-numberofdraws-text flex flex-between items-center"
            style="margin-top: 30px"
          >
            <div>1.邀请奖励</div>
            <!-- <div class="flex items-center">
              <el-progress
                :percentage="numberofguests"
                :stroke-width="22"
                color="#D56DC4"
                :show-text="false"
                define-back-color="#5F4E81"
              ></el-progress>
              <div style="margin-left: 16px">
                <span style="color: #5b76ff">{{
                  Math.floor(nameSubordinate.length / 2)
                }}</span
                >/25
              </div>
            </div> -->
          </div>
          <div
            class="popupClass-content-numberofdraws-text flex flex-between items-center"
          >
            <div>2.每日签到</div>
            <div class="flex items-center">
              <el-progress
                :percentage="isSignin ? 100 : 0"
                :stroke-width="22"
                color="#D56DC4"
                :show-text="false"
                define-back-color="#5F4E81"
              ></el-progress>
              <div style="margin-left: 34px">
                <span>{{ isSignin ? 1 : 0 }}</span
                >/1
              </div>
            </div>
          </div>
          <div>
            <div
              class="popupClass-content-numberofdraws-text flex flex-between items-center"
            >
              3.完成任务
            </div>
            <div>
              <div
                class="popupClass-content-numberofdraws-text flex flex-between items-center"
                style="margin-left: 20px"
                v-for="(item, index) in taskList"
                :key="index"
              >
                <div class="popupClass-content-numberofdraws-text-t">
                  {{ item.taskName }}——上传截图
                </div>
                <img
                  :src="
                    item.submitStatus == 1
                      ? 'https://cdn.bubbleplan.cn/m_bubble/turntableRaffle/Group9864.png'
                      : 'https://cdn.bubbleplan.cn/m_bubble/turntableRaffle/Group9865.png'
                  "
                  mode=""
                  class="popupClass-content-numberofdraws-text-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="popupClass-content-numberofdraws-button"
        @click="numberofdrawsShow = false"
      >
        好的
      </div>
    </div>
  </el-dialog>
</template>
      <script>
export default {
  props: {
    taskList: Array,
    nameSubordinate: Array,
    isSignin: [Number, String, Boolean],
  },
  data() {
    return {
      numberofdrawsShow: false,
    };
  },
  computed: {
    numberofguests() {
      if (this.nameSubordinate.length == 0) {
        return 0;
      } else if (this.nameSubordinate.length >= 50) {
        return 100;
      } else {
        let a = 100 / 50;
        return this.nameSubordinate.length * a;
      }
    },
  },
  methods: {},
};
</script>
      
  <style lang="scss" scoped>
.numberofdraws {
  width: 936.32px;
  height: 792.77px;
  background: url("https://cdn.bubbleplan.cn/static/drawalotteryorraffle/Group9870.png")
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding-top: 94px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 88px 114px 103.34px 158px;
  &-x {
    cursor: pointer;
  }
  &-x {
    position: absolute;
    top: 63.96px;
    right: 36.76px;
    width: 80.88px;
    height: 79.56px;
  }
  &-title {
    width: 100%;
    color: #574352;
    font-size: 48px;
    font-weight: 900;
    text-align: center;
  }

  &-text {
    height: 430px;
    overflow: auto;
    margin-top: 40px;
    color: #574352;
    font-size: 24px;
    line-height: 40.8px;
    // background: #d6c4ba;
    padding: 0px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    .popupClass-content-numberofdraws-title {
      width: 100%;
      color: #574352;
      font-size: 28px;
      font-weight: 700;
      text-align: center;
      margin-top: 20px;
    }
    .popupClass-content-numberofdraws::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    .popupClass-content-numberofdraws {
      flex: 1;
      overflow: auto;
      :deep(.el-progress) {
        width: 207px !important;
      }
      width: 100%;
      padding-top: 20px;
      padding: 0 50px;

      &-text {
        color: #574352;
        font-size: 26px;
        line-height: 53.2px;

        &-t {
          width: 90%;
          color: #574352;
          font-size: 26px;
        }

        &-img {
          width: 37px;
          height: 37px;
        }
      }
    }
  }
  .popupClass-content-numberofdraws-button:hover {
    cursor: pointer;
  }
  .popupClass-content-numberofdraws-button {
    margin: auto;

    width: 211px;
    height: 88px;
    color: #3e2839;
    text-align: center;
    font-family: "qiantuhouheiti";
    font-size: 44px;
    line-height: 88px;
    background: url("https://cdn.bubbleplan.cn/m_bubble/turntableRaffle/Vector607.png")
      no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 640px;
    left: 385px;
    z-index: 999;
  }
}
</style>